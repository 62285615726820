export { setCompletedAt, setFailedAt, setStartedAt, setResetAt };

function setCompletedAt(task) {
  $('.box-placeholder').hide();
  $('#failed-reason-1-' + task.id + ', #failed-reason-2-' + task.id).css('display', 'none');
  $('#completed_at-' + task.id).html('<b>Completed</b><br>' + task.completed_at);
  $('#status-' + task.id).html('');
}

function setFailedAt(task) {
  $('.box-placeholder').hide();
  if (task.failed_reason != '') {
    $('#failed-reason-1-' + task.id + ', #failed-reason-2-' + task.id).css('display', 'block');
    $('#failed-reason-2-' + task.id).html(task.failed_reason);
  }
  $('#completed_at-' + task.id).html('<b>Failed</b><br>' + task.failed_at);
  $('#status-' + task.id).html('');
}

function setStartedAt(task) {
  $('.box-placeholder').hide();
  let str = '<b>Started</b>';
  if (task.time_left_to_complete != "∞") {
    str += '<br><i>' + task.time_left_to_complete + '</i> left to complete';
  }
  $('#status-' + task.id).html(str);
}

function setResetAt(task) {
  $('.box-placeholder').hide();
  $('#reset_at-' + task.id).html('<b>Reset</b><br>' + task.reset_at + '<br><span style="color: #ffc107;">' + task.failed_reason + '</span>');
  $('#status-' + task.id).html('');
}
