/*global groupManagerId*/
/*eslint no-undef: "error"*/

export default function _default() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('archived') == 'true') {
    $('#include-deleted').prop('checked', true);
  }

  $('#include-deleted').on('click', function() {
    if ($('#include-deleted').is(':checked')) {
      window.location = '/experiments/group_managers/' + groupManagerId + '/participants?archived=true';
    } else {
      window.location = '/experiments/group_managers/' + groupManagerId + '/participants';
    }
  });

  $('.btn-delete').on('click', function() {
    let participantId = $(this).data("participant-id");
    $("#btn-delete-modal").data("participant-id", participantId);
  });

  $('#btn-delete-modal').on('click', function() {
    let participantId = $(this).data("participant-id");
    $.ajax({
        type: 'GET',
        url: "/accounts/" + participantId + "/delete",
        success: function () {
          window.location.reload();
          console.log("Account deleted for user with id " + participantId + " deleted.");
        },
        error: function (response) {
          console.log(response);
          alert("There was an error deleting the account. Please write an email to: cap-information@ae.mpg.de");
        }
      });
  });

  $('.participant-row').dblclick(function() {
    window.location = "/experiments/participants/" + $(this).data('participant-id') + "/tasks";
  });
}
