export { addTargetingClass, removeTargetingClass };

$( document ).ready(function() {
  $(".alert").delay(5000).slideUp(400);

  $('#save-experiment-button').on('click', function() {
    $('#id_group_managers option').prop('selected', true);
  });

  $(document).on("focus",".comment-textarea", function () {
    hideSaveCommentButton($(this));
  });

  $(document).keyup(function(e) {
    if (e.key === "Escape") {
      hideSaveCommentButton($(this));
    }
  });

  $(document).on("click",".save-comment-button", function () {
    let comment = $(this).parent().prev().val();
    let task_id = $(this).parent().prev().data('task-id');
    let url = '/experiments/tasks/' + task_id + '/update_comment';
    $.post(url, {'task_id': task_id, 'comment': comment});
    $(".comment-buttons").css("display", "none");
  });

  $('#export-tasks').click(function() {
    window.location = "tasks/export";
  });

  $('#export-participants').click(function() {
    window.location = "participants/export";
  });

  // Edit bonus
  $('.bonus').dblclick(function() {
    $(this).hide();
    $(this).next().show();
  });

  $(".editable_field").on('keyup', function (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      update_bonus($(this).data('task-id'), $(this).val());
    }
  });

  $('html').click(function(e) {
    if (!$(e.target).hasClass('editable_field')) {
      $('.editable_field_container').hide();
      $('.bonus').show();
    }
  });

  //Bugfix not displaying of date form field validation error message
  $(".invalid-feedback").toggleClass('invalid-feedback text-danger');
  if (window.location.pathname.includes('experiments/update')) {
    updateDashboardUrl($("#id_url").val());
  }
  $("#id_url").on('keyup', function () {
    updateDashboardUrl($(this).val());
  });

  $("#profile-link, #profile-dropdown").mouseover(function(){
    $("#profile-dropdown").show();
  });
  $("#profile-link, #profile-dropdown").mouseout(function(){
    $("#profile-dropdown").hide();
  });
  $("#profile-link").click(function() {
    if ($('#profile-dropdown').is(':visible')) {
      $("#profile-dropdown").hide();
    } else {
      $("#profile-dropdown").show();
    }
  });
});

function hideSaveCommentButton(elem) {
  $(".comment-buttons").css("display", "none");
  $(elem).next().css("display", "block");
}


function update_bonus(task_id, bonus) {
  $.post('/tasks/' + task_id + '/update_bonus', { bonus: bonus }).done(function() {
    window.location = window.location.pathname;
  });
}

function updateDashboardUrl(url) {
  $("#id_dashboard_url").val(url.replace(new RegExp('/ad$'), "/dashboard"));
}

function addTargetingClass(logEntryPk) {
  $('#log-entries-last-' + logEntryPk).addClass('target-highlight');
}

function removeTargetingClass(logEntryPk) {
  $('#log-entries-last-' + logEntryPk).removeClass('target-highlight');
}
