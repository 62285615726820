import '../styles/base.scss';
import '../styles/hijack.css';

import './base.js';
import './sort.js';
import './group_manager/index.js';
import './superuser/index.js';
import './worker/index.js';


document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll(
    '.frontend-module'
  ).forEach((element) => {
    if ('name' in element.dataset) {
      import(
        `./${element.dataset.name}`
      ).then((module) => {
        module.default();
      });
    }
  });
});

