/* global wsProtocol, wsHost, customUserId */
/* eslint no-undef: "error" */

import { addTargetingClass, removeTargetingClass } from '../base.js';

export default function connect() {
  var socket = new WebSocket(wsProtocol +'://' + wsHost + '/wss/admin_notifications/');

  socket.onopen = function() {
    socket.send(JSON.stringify({
      'custom_user_id': customUserId,
    }));
  };

  socket.onmessage = function(event) {
    $('#log-entries').text('');

    $.each(JSON.parse(event.data), function(logEntryPk, logEntry) {
      $('#log-entries').append('<span id="log-entry-' + logEntryPk + '">' + logEntry.nat_lang + '</span><br>');
      $('#log-entry-naturaltime').text(logEntry.timestamp);

      if ($('#log-entries-last').text() == '' || $('#log-entries-last-' + logEntryPk).text() == '') {
        let highlight = $('#log-entries-last').text() != '' ? true : false;
        $('#log-entries-last').html('<span id="log-entries-last-' + logEntryPk + '" style="font-weight: bold;">' + logEntry.nat_lang + '</span>');
        if (highlight) {
          addTargetingClass(logEntryPk);
        }
        setTimeout(function() {
            removeTargetingClass(logEntryPk);
        }, 5000);
      }
    });

    socket.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect in one second.', e.reason);
      setTimeout(function() {
        connect();
      }, 2000);
    };

    socket.onerror = function(err) {
      console.error('Socket error: ', err.message, 'Closing socket.');
      socket.close();
    };
  };
}
