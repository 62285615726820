/*global wsProtocol, wsHost, customUserId*/
/*eslint no-undef: "error"*/

export default function connect() {
  var socket = new WebSocket(wsProtocol +'://' + wsHost + '/wss/admin_experiments/');

  socket.onopen = function() {
    socket.send(JSON.stringify({
      'custom_user_id': customUserId,
    }));
  };

  socket.onmessage = function(event) {
    $.each(JSON.parse(event.data), function(experimentId, experiment) {
      $('#tasks-count-' + experimentId).text(experiment.tasks_count);
    });

    socket.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect in one second...', e.reason);
      setTimeout(function() {
        connect();
      }, 1000);
    };

    socket.onerror = function(err) {
      console.error('Socket error: ', err.message, 'Closing socket.');
      socket.close();
    };
  };

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('archived') == 'true') {
    $('#show-archived').prop('checked', true);
  }

  $('#show-archived').on('click', function() {
    if ($('#show-archived').is(':checked')) {
      window.location = "/experiments/experiments?archived=true";
    } else {
      window.location = "/experiments/experiments";
    }
  });

  $('.btn-delete').on('click', function() {
    let experimentId = $(this).data("experiment-id");
    $("#delete-experiment").data("experiment-id", experimentId);
  });

  $('#delete-experiment').on('click', function() {
    let experimentId = $(this).data("experiment-id");
    console.log("DELETING experiment with ID '" + experimentId + "'.");
    $.ajax({
      type: 'GET',
      url: "/experiments/" + experimentId + "/delete",
      success: function () {
        console.log("Experiment with id '" + experimentId + "' deleted.");
        window.location.reload();
      },
      error: function (response) {
        console.log("There was an error deleting the experiment:");
        console.log(response);
      }
    });
  });

  $(".show_all_toggle").unbind("click").click(function() {
    let experiment_id = $(this).data('experiment-id');
    $(".show_all_toggle_" + experiment_id).toggle();
    $(".show_all_" + experiment_id).toggle();
  });

  $('.experiment-row').dblclick(function() {
    $("body").css("cursor", "wait");
    window.location = "/experiments/" + $(this).data('experiment-id') + "/tasks";
  });
}
