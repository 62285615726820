/*global wsProtocol, wsHost, customUserId*/
/*eslint no-undef: "error"*/

export default function connect() {
  var socket = new WebSocket(wsProtocol +'://' + wsHost + '/wss/admin_participants/');

  socket.onopen = function() {
    socket.send(JSON.stringify({
      'custom_user_id': customUserId,
    }));
  };

  socket.onmessage = function(event) {
    $.each(JSON.parse(event.data), function(participantId, participant) {
      $('#tasks-count-' + participantId).text(participant.tasks_count);
    });

    socket.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect in one second.', e.reason);
      setTimeout(function() {
        connect();
      }, 1000);
    };

    socket.onerror = function(err) {
      console.error('Socket error: ', err.message, 'Closing socket.');
      socket.close();
    };
  };

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('with_deleted') == 'true') {
    $('#include-deleted').prop('checked', true);
  }

  $('#include-deleted').on('click', function() {
    if ($('#include-deleted').is(':checked')) {
      window.location = "/experiments/participants?with_deleted=true";
    } else {
      window.location = "/experiments/participants";
    }
  });

  $('.btn-delete').on('click', function() {
    let participantId = $(this).data("participant-id");
    $("#delete-account").data("user-id", participantId);
  });

  $('#delete-account').on('click', function() {
    let userId = $(this).data("user-id");
    $.ajax({
      type: 'GET',
      url: "/accounts/" + userId + "/delete",
      success: function () {
        console.log("Account for user with id '" + userId + "' deleted.");
        window.location.reload();
      },
      error: function (response) {
        console.log("There was an error deleting the account:");
        console.log(response);
      }
    });
  });

  $('.participant-row').dblclick(function() {
    $("body").css("cursor", "wait");
    window.location = "/experiments/participants/" + $(this).data('participant-id') + "/tasks";
  });
}
