export default function _default() {
  $('#delete-account').on('click', function() {
    let userId = $(this).data("user-id");
    $.ajax({
      type: 'GET',
      url: "/accounts/" + userId + "/delete",
      success: function () {
        window.location = "/accounts/login";
      },
      error: function (response) {
        console.log(response);
        alert("There was an error deleting your account. Please write an email to: cap-information@ae.mpg.de");
      }
    });
  });
}
