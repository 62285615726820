"use strict";
/*global wsProtocol, wsHost, customUserId, groupManagerCode, workerId, email, debugMode, hideModal*/
/*eslint no-undef: "error"*/

import { v4 as uuidv4 } from 'uuid';

export default function connect() {
  var socket = new WebSocket(wsProtocol +'://' + wsHost + '/wss/worker_tasks/');
  var reloaded = false;

  socket.onopen = function() {
    socket.send(JSON.stringify({
          'custom_user_id': customUserId,
      }));
  };

  socket.onmessage = function(event) {
    let data = JSON.parse(event.data);

    if (!reloaded) {
      if ($('#reload-page').length == 1 && data['participant_is_verified'] ||
          $('#start-test-task').length == 1 && data['started_test_task'] ||
          $("#restart-test-task").length == 1 && (!data['started_test_task'] || data['test_task_verified'])) {
        reloaded = true;
        console.log("Reloading...");
        window.location.reload();
      }
    }

    $.each(data.tasks, function(taskId, task) {
      $('#started_at-' + taskId).text(task.started_at);
      if (task.state == 'completed') {
        setCompletedAt(task);
      } else if (task.state == 'not_completed') {
        setFailedAt(task);
      } else if (task.state == 'started') {
        setStartedAt(task);
      } else if (task.state == 'reset') {
        setResetAt(task);
      }

      $('#duration-' + taskId).text(task.duration);
      if (['paid', 'received'].includes(task.payment.state)) {
        if (task.state == "completed") {
          setCompletedAt(task);
        } else if (task.state == "failed") {
          setFailedAt(task);
        }
      }

      if (task.payment.is_paid) {
        $('#payment_processed-' + taskId).text('✓');
      } else {
        $('#payment_processed-' + taskId).text('');
      }
      if (task.payment_received) {
        $('#payment_received-' + taskId).text('✓');
      } else {
        $('#payment_received-' + taskId).text('');
      }
      if (task.payment.state == 'paid') {
        $('#payment_done-' + taskId).show();
      } else {
        $('#payment_done-' + taskId).hide();
      }

      $('#base_payment-' + taskId).text(task.base_payment);
      $('#bonus-' + taskId).text(task.bonus);
      let total_payment = task.state == 'reset' ? '<span style="color: #ffc107;">' + task.payment.amount + '</span>' : task.payment.amount;
      $('#total_payment-' + taskId).html(total_payment);

      if (data['has_outstanding_payments']) {
        $('#total-payments-row').css('border-bottom', '');
        $('#outstanding_payments_sum').text('$' + data['outstanding_payments_in_dollars']);
        $('#outstanding_payments').show();
        if (data['has_finished_non_test_task']) {
          $('#outstanding_payments_sum').addClass('outstanding_payment');
          $('#outstanding_payments_sum').removeClass('test_task_payment');
        }
      } else {
        $('#outstanding_payments').hide();
        $('#total-payments-row').css('border-bottom', '2px solid #00ce4c');
      }
    });

    $('#total-payments').text(data['total_payments']);

    socket.onclose = function(e) {
      console.log('Socket is closed. Attempting to reconnect in one second.', e.reason);
      setTimeout(function() {
        connect();
      }, 1000);
    };

    socket.onerror = function(err) {
      console.error('Socket error: ', err.message, 'Closing socket.');
      socket.close();
    };
  };
}

$(document).ready(function() {
  $(".experiment-link").one('click', function() {
    let batch = $(this).data("batch");
    let batches = $(this).data("batches");
    let experimentUrl = $(this).data("experiment-url");
    let experimentId = $(this).data("experiment-id");
    let assignmentId = uuidv4().split('-')[0];
    let _workerId = workerId;

    if (batches > 1) {
      _workerId = workerId + "-b" + batch;
    }

    $(this).data("assignment-id", assignmentId);

    $('#confirm-experiment-start').one('click', '.btn-start', function() {
      $.ajax({
        type: 'GET',
        url: "/experiments/tasks/start",
        data: {"experimentId": experimentId, "groupManagerCode": groupManagerCode, "assignmentId": assignmentId, "batch": batch},
        success: function (response) {
          let experimentLink = experimentUrl + "?workerId=" + _workerId + "-" + response.task_id + "&groupId=" + groupManagerCode + "&assignmentId=" + assignmentId + "&hitId=" + response.task_id + "&email=" + email + debugMode;
          $("#experiment-link-" + experimentId).remove();
          hideModal();
          window.open(experimentLink);
          window.location.reload();
        },
        error: function (response) {
          console.log(response);
        }
      });
    });
  });
});

function setCompletedAt(task) {
  $('.box-placeholder').hide();
  $('#failed-reason-1-' + task.id + ', #failed-reason-2-' + task.id + ', #failed-reason-3-' + task.id).css('display', 'none');
  $('#completed_at-' + task.id).html('<b>Completed</b><br>' + task.completed_at);
  $('#status-' + task.id).html('');
}

function setFailedAt(task) {
  $('.box-placeholder').hide();
  if (task.failed_reason !== '') {
    $('#failed-reason-1-' + task.id).html('<b>Experiment failed</b><br>' + task.failed_at);
    $('#failed-reason-1-' + task.id + ', #failed-reason-2-' + task.id).css('display', 'block');
    $('#failed-reason-2-' + task.id).html(task.failed_reason);
  }
  $('#failed-reason-3-' + task.id).css('display', 'block');
  $('#status-' + task.id).html('');
}

function setStartedAt(task) {
  $('.box-placeholder').hide();
  let str = '<b>Started</b>';
  str += '<br><i>' + task.started_at + '</i>';
  if (task.time_left_to_complete != "∞") {
    str += '<br><i>' + task.time_left_to_complete + '</i> left to complete';
  }
  $('#status-' + task.id).html(str);
}

function setResetAt(task) {
  $('.box-placeholder').hide();
  $('#reset_at-' + task.id).html('<b>Reset</b><br>' + task.reset_at + '<br><span style="color: #ffc107;">' + task.failed_reason + '</span><br>');
  $('#status-' + task.id).html('');
}
