export default function _default() {
  $('#copy-participant-email').on('click', function() {
    console.log($(this).data('participant-email'));
    let element = document.createElement('textarea');
    element.value = $(this).data('participant-email');
    document.body.appendChild(element);
    element.select();
    document.execCommand('copy');
    document.body.removeChild(element);
  });
}
