$(document).ready(function() {
  var urlParams = new URLSearchParams(window.location.search);
  $('.sort_' + urlParams.get('sort_by') + '.' + urlParams.get('sort_dir')).hide();
  var sortable_columns = [
    'id',
    'first_name',
    'last_name',
    'worker_id',
    'test_task_verified',
    'email',
    'date_joined',
    'title',
    'description',
    'url',
    'start_date',
    'expire_date',
    'estimated_duration',
    'max_duration',
    'batches',
    'created_at',
    'updated_at',
    'tasks_count',
    'demographic_info__gender',
    'demographic_info__years_of_experience',
    'demographic_info__date_of_birth',
    'outstanding_payments_sum',
    'country_name',
    'gender',
    'years_of_experience',
    'is_verified',
    'age',
    'worker_tasks_count',
    'name',
    'group_manager_full_name',
    'group_manager_name',
    'group_manager_consent',
    'participants_not_deleted_count',
    'enable_experiment_start_buttons',
    'nonresponsive',
  ];
  $($.map(sortable_columns, function(val) { return ".sort_" + val; }).join(', ')).click(function() {
    let column = $(this).attr('class').split(' ')[0].split('sort_')[1];
    let dir = $(this).attr('class').split(' ')[1];
    sort_by(column, dir, urlParams);
   });
});

function sort_by(column, dir, urlParams) {
  let request_string = '';
  urlParams.forEach(function(key, value) {
    if (!['sort_by', 'sort_dir'].includes(key)) {
      request_string += key + "=" + value + "&";
    }
  });

  let location_pathname = window.location.pathname;
  if (window.location.pathname == ('/experiments/')) {
    location_pathname += 'experiments';
  }
  window.location = location_pathname + "?" + request_string + "sort_by=" + column + "&sort_dir=" + dir;
}
